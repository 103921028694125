import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Dictionary } from '../shared/components/filtering-search-component-base/filtering-search-types'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public redirectUrl: string = ''
  public redirectUrlUsed: boolean = false
  result = false

  constructor(private http: HttpClient) { }

  login(UserName: string, Password: string)  {
    return this.http.post(environment.baseUrl + 'login', {
      UserName,
      Password,
    },{
      withCredentials: true
    })
  }

  loginByIPAddress() {
    return this.http.get(environment.baseUrl + 'loginbyip', {
      withCredentials: true
    })
  }

  forgottenPassword(UserName: string)  {
    return this.http.post(environment.baseUrl + 'forgottenPassword', {
      UserName
    },{
    })
  }

  forgottenPasswordLink(userID: number)  {
    return this.http.post(environment.baseUrl + 'forgottenPasswordLink', {
      userID
    },{
      withCredentials: true
    })
  }

  requestTrial(trial: Dictionary<string>)  {
    return this.http.post(environment.baseUrl + 'requestTrial', {
      trial,
    },{
      withCredentials: true
    })
  }

  validateTokenCookie(token: string  = '')  {
    if (token !== '') {
      token = '/' + token
    }
    return this.http.get(environment.baseUrl + 'validate' + token, {
      withCredentials: true
    })
  }
  validateActivate(contactID: number, email: string)  {
    return this.http.get(`${environment.baseUrl}validate-activation/${contactID}/${email}`, {
      withCredentials: true
    })
  }

  activateAccount(contactID: number, token: string, password: string)  {
    return this.http.post(`${environment.baseUrl}activate-account/${contactID}`, {
      token,
      password
    },{
      withCredentials: true
    })
  }

  validateJWT(jwt: string  = '')  {
    return this.http.get(environment.baseUrl + 'legacy/' + jwt, {
      withCredentials: true
    })
  }

  logout() {
    return this.http.get(environment.baseUrl + 'logout', {
      withCredentials: true
    })
  }
}
